<template>
  <div>
    <h5 class="primary--text fw-700 font-14px text-center mb-6">
      人件費率
    </h5>
    <div class="font-12px text-333 line-height-15 mb-6">
      <p>
        人件費率は、売上に対する人件費の割合です。<br />
        一般的に、食材原価率と人件費率の合計が60%以内が理想とされています。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LaborCostRate"
};
</script>
